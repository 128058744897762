import moment from "moment-timezone";
import Pagination from "@/components/Pagination";
import ListMixin from "@/mixins/list-page.js"

export default {
    name: "PaymentsList",
    components: {
        Pagination,
    },
    mixins: [ListMixin],
    data() {
        return {
            userId: null,
            userFilter: null,
            filterId: this.$route.query.loanId ?? "",
            fromFilterDate: null,
            toFilterDate: null,
            useDateFilter: false,
            sum: null,
        };
    },
    computed: {
        minDate() {
            let fromDate = new Date(this.fromFilterDate);
            return fromDate || null;
        },
        maxDate() {
            return new Date();
        },
        filtered () {
            return this.$route.query.userId || this.$route.query.loanId || this.$route.query.dateFrom || this.$route.query.dateTo ? true : false;
        },
    },
    created() {
        this.userFilter = this.$auth.check(["admin", "senior_collector"]);
    },
    mounted() {
        this.userId = this.userFilter ? (this.$route.query.userId ?? 0) : this.$auth.user().id;

        this.fields = ([
            {
                key: "id",
                label: "ID договора",
            },
            {
                key: "fio",
                label: "ФИО",
                formatter(value, key, item) {
                    return `${item.loan.lastName} ${item.loan.firstName} ${item.loan.patronymic ?? ""}`;
                },
            },
            {
                key: "time",
                sortable: true,
                label: "Дата и время платежа",
                formatter(value, key, item) {
                    return window.formatDatetime(item.payAt);
                },
            },
            {
                key: "amount",
                label: "Сумма",
                formatter(value, key, item) {
                    return item.sum.toLocaleString();
                },
            },
            {
                key: "overdueDay",
                label: "День просрочки",
            },
            this.userFilter ? {
                key: "user",
                label: "Коллектор",
                formatter(value, key, item) {
                    return item.user ? item.user.name : "-";
                },
            } : null,
        ]).filter((el) => el !== null);

        this.fromFilterDate = (this.$route.query.dateFrom ? moment(this.$route.query.dateFrom) : moment()).format("YYYY-MM-DD");
        this.toFilterDate = (this.$route.query.dateTo ? moment(this.$route.query.dateTo) : moment()).format("YYYY-MM-DD");
    },
    methods: {
        onCorrectToDate() {
            return this.fromFilterDate > this.toFilterDate ? this.toFilterDate = this.fromFilterDate : this.toFilterDate;
        },
        onQueryChange(query) {
            if (!query.currentPage || !query.perPage) {
                this.$router.replace({query: this._getQueryParams({})}).catch(() => {});
            }
        },

        _getQueryParams(options) {
            const params = Object.assign({
                currentPage: this.pagination.currentPage,
                perPage: this.pagination.perPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                userId: parseInt(this.userId),
                loanId: this.filterId,
                dateFrom: this.useDateFilter ? `${this.fromFilterDate}` : null,
                dateTo: this.useDateFilter ? `${this.toFilterDate}` : null,
            }, options);

            return Object.fromEntries(Object.entries(params).filter(([, v]) => v != null && !!v));
        },
        request(query) {
            return this.jsonRpc("admin.payments.list", query)
                .then(async (result) => {
                    this.errorServe = false;
                    this.pagination.totalItems = result.payments.totalItems;
                    this.sum = result.payments.meta.total_sum;

                    return result.payments.items;
                });
        },
    }
}
